import classes from './Header.module.css';
import React from 'react';
import im from './Finall.png'

const Header = () => {
    return (
        <React.Fragment>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}} >
                <h1 className={classes['brand-name']} >SaiCharang08</h1>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 30, alignItems: 'center'}}>
                    <p className={classes['section-head']}><a href="#work" >Work</a></p>
                    <p className={classes['section-head']}><a href="#services" >Services</a></p>
                    <p className={classes['section-head']} style={{color: '#B1B9B3', backgroundColor: '#213827', borderRadius: '40px', padding: '10px 20px'}}>
                        <a href="#contact" style={{color: '#B1B9B3'}} >Get in Touch</a>
                        </p>
                </div>
                {/* <div className={classes['hamburger']} >
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
            </div>
        </React.Fragment>
    )
}

export default Header;