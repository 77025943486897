import React from "react";
import im from './Finall.png'
import classes from './Intro.module.css';

const Intro = () => {
    return (
        <div className={classes['intro-container']} >
            <p className={classes['about']} >
            Hi, I am SaiCharan. I am Freelance Web Developer. I help you in building websites. I can also do other software related works. You can see my work here. Explore different services I offer. Contact me for more details. 
            </p>
            <img src={im} className={classes['image']} />
        </div>
    )
}

export default Intro;