import Header from './components/Header';
import './App.css';
import Intro from './components/Intro';
import Work from './components/Work';
import Services from './components/Services';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
      <Header />
      <Intro />
      <hr style={{margin: '0px', padding: '0px', border: '1px solid #959f9b'}} />
      <Work />
      <hr style={{margin: '0px', padding: '0px', marginTop: '16px', border: '1px solid #959f9b'}} />
      <Services />
      <hr style={{margin: '0px', padding: '0px', marginTop: '16px', border: '1px solid #959f9b'}} />
      <Contact />
    </div>
  );
}

export default App;
