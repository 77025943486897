import React from "react";

const Services = () => {
    return (
        <React.Fragment>
            <p style={{fontSize: '40px'}} id = "services">Services</p>
            <p style={{fontSize: '30px'}}>Static Websites</p>
            <ul>
                <li style={{fontSize: '24px'}} >Static websites are good if the content of website won’t change frequently.</li>
                <li style={{fontSize: '24px'}} >These type of websites costs less. But, you needs assistance to change content of the website.</li>
                <li style={{fontSize: '24px'}} >If the content on the website changes frequently go for Dynamic Websites instead.</li>
            </ul>
            <p style={{fontSize: '30px'}}>Dynamic Websites</p>
            <ul>
                <li style={{fontSize: '24px'}} >As the name suggests these websites are dynamic and mostly widely used ones.</li>
                <li style={{fontSize: '24px'}} >These type of websites costs little more for development and maintenance.</li>
                <li style={{fontSize: '24px'}} >The cost for these websites depends on the requirements and what are the services we need to plug in to develop the website.</li>
            </ul>
        </React.Fragment>
    )
}

export default Services;