const Project = (props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', paddingBottom: '10px', borderRadius: '16px', backgroundColor: '#D9D9D9'}} >
            <img src={props.img} style={{ borderTopRightRadius: '16px', width: '100%', borderTopLeftRadius: '16px'}}/>
            <h3 style={{margin: '0px', padding: '4px 10px', fontWeight: 500}} >{props.name}</h3>
            <p style={{margin: '0px', padding: '4px 10px', fontSize: '12px'}} >{props.description}</p>
        </div>
    )
}

export default Project;