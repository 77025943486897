import React, { useState } from "react";
import Project from "./Project";
import classes from "./Work.module.css";

// images
import billingsoftware from '../images/billingsoftware.png';
import kulswamini from '../images/kulswamini.png';
import spmusic from '../images/spmusic.png';
import emailperm from '../images/emailpermutator.png';
import emailextract from '../images/emailextractor.png';

const Work = () => {
    const [freelance, setFreelance] = useState([
        {name: "Billing Software", description: "Complete", img: billingsoftware},
        {name: "Kulswamini", description: "Complete", img: kulswamini},
        {name: "SP Music", description: "Complete", img: spmusic},
        {name: "Email Permutator", description: "Complete", img: emailperm},
        {name: "Email Extractor", description: "Complete", img: emailextract},
    ])


    return(
        <React.Fragment>
            <p style={{fontSize: '40px'}} id="work" >Work</p>
            <div className={classes["projectssection"]} >
                {freelance.map((val, index) => <Project key={index} {...val} />)}
            </div>
        </React.Fragment>
    )
}

export default Work;