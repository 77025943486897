const Contact = () => {
    return (
        <>
        <p style={{fontSize: '40px'}} id="contact" >Contact</p>
        <div>
            <span style={{paddingBottom: '20px', fontFamily: 'Calisto MT', fontSize: '32px', margin: '0 auto'}}> Please mail me at <a href="mailto:charan@saicharang08.com" style={{fontStyle: 'italic'}} >charan@saicharang08.com</a> to get in touch.</span>
        </div>
        </>
    )
}

export default Contact;